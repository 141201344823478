<template>
  <div :class="layoutPcMi ? 'good-detail' : 'good-detail-ml'">
    <el-breadcrumb style="margin-bottom:20px" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item>搜索</el-breadcrumb-item>
    </el-breadcrumb>

      <div v-loading="loading" :style="{height: loading ? '100px' : ''}" :class="layoutPcMi ? 'goods-flex' : 'goods-flex-ml'">
        <div v-for="(item,index) in searchList" :key="index" class="good-item"
          @mouseover="goodMouseOver(index)"
          @mouseleave="goodMouseLeave(index)"
          @click="rouGoodDetail(item.id)">
          <div class="good-content">
            <div class="img">
            <div class="img-bg"></div>
            <img  :src="item.img" alt="">
            <div class="hover-btn" v-show="item.hover">
            <el-tooltip  effect="dark" content="收藏" placement="top-start">
 
              <div class="good-collect" @click.stop="collect(item.id)"><i class="el-icon-star-off"></i></div>
             </el-tooltip>
                <el-tooltip  effect="dark" content="对比" placement="top-start">
               <div @click.stop="toContrast(item)" class="good-collect"><i class="el-icon-sort"></i></div>
            </el-tooltip>
            </div>
          </div>
          <div class="evaluation">
            <!-- <i class="el-icon-star-on"></i>
            <i class="el-icon-star-on"></i>
            <i class="el-icon-star-on"></i>
            <i class="el-icon-star-on"></i>
            <i class="el-icon-star-on"></i> -->
            <el-rate disabled-void-color="#d2d2d2" :value="item.star > 5 ? 5 : item.star" disabled></el-rate>
          </div>
          <p>{{item.name}}</p>
          <div class="add-cat">
            <el-button class="el-button-add-cart" v-show="item.hover"><i class="el-icon-shopping-cart-full"></i>加入购物车</el-button>
          </div>
          <p class="price">￥{{item.price}}</p>
          </div>
      </div>
    </div>
      <div style="text-align:center;margin-top:50px" v-if="searchList.length == 0 && !loading">暂无数据</div>
  </div>
</template>

<script>
import vuePhotoZoomPro from 'vue-photo-zoom-pro'
import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css'
import { http } from '../../../request/http'
export default {
  name: 'goodDetail',
  components: {
    vuePhotoZoomPro
  },
  data () {
    return {
      layoutPcMi: this.$store.state.layoutPcMi,
      searchList:[],
      hoverBtn:false,
      loading:false
    }
  },
  mounted() {
    //   this.getProductList()
  },
  watch:{
     //监听路由地址的改变
	    $route:{
	        immediate:true,
	        handler(){
	            if(this.$route.query.searchval){//需要监听的参数
	               this.getProductList()
	            }
	        }
	    }
  },
  methods: {
      // 跳转对比页面
    toContrast(data) {
      console.log(data);
      let list = JSON.parse(localStorage.getItem('contrastList')) || [];
      let id = []
      if(list.length > 0) {
        list.forEach(item=>{
          id.push(item.id)
        })
        id = id.join(',')
      }  
      if(list.length == 4 && id.indexOf(data.id) == -1) {
        list[3] = data
      } else if(list.length < 4 && id.indexOf(data.id) == -1) {
        list.push(data)
      }
      localStorage.setItem('contrastList',JSON.stringify(list))
      this.$router.push('/goodsContrast')
    },
    // 收藏
    collect(id) {
      http('column.php', {
					fun: 'addColumnCollect',
					type: 'c',
          module:'goods',
          bind_id: id
				}).then(res => {
          if(res.code == 0) {
             this.$message({
                message: '收藏成功',
                type: 'success'
              });
           this.$emit('collectChange')
          }
				})
    },
    goodMouseOver (index) {
      // this.hoverBtn = true
      this.searchList[index].hover = true

    },
    goodMouseLeave (index) {
      // this.hoverBtn = false
      this.searchList[index].hover = false
    },
    rouGoodDetail(id) {
      this.$router.push('/detail?id=' + id)
    },
   

    // 获取商品列表
			getProductList() {
        this.loading = true
        let searchVal = this.$route.query.searchval
				http('goods.php', {
					fun: 'getGoodsEx',
					start_num:0,
					get_num: 10,
          goods_name: searchVal
				}).then(res => {
          console.log(res);
					if(res.code == 0) {
            this.searchList = res.data
            this.searchList.forEach(item=>{
              this.$set(item,'hover',false)
            })
            this.loading = false
					}
				})
		},
  }
}
</script>

<style scoped lang="scss">
@import "../../scss/index.scss";
.good-detail {
  @include body;
  .detail-content {
    margin-top: 20px;
    display: flex;
    .con-left {
      width: 40%;
      display: flex;
      flex-direction: column;
      .detail-img-list {
        display: flex;
        margin-top: 20px;
        div {
          margin-right: 20px;
          width: 60px;
          height: 60px;
          padding: 10px;
          border: 1px solid #99a9bf;
          border-radius: 10px;
          @include flexCenter;
          background-color: darken(#F2F2F4, 10%);
          img {
            width: 100%;
          }
        }
      }
      .img {
        width: 100%;
        padding: 10vh 0;
        background-color: #F1F2F5;
        @include flexCenter;
        img {
          width: 60%;
        }
      }
    }
    .con-right {
      width: 60%;
      margin-left: 40px;
      .title {
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}
.good-detail-ml {
  width: 100%;
  @extend .good-detail;
  .detail-content {
    flex-wrap: wrap;
    .con-left {
      width: 100%;
    }
    .con-right {
      width: 100%;
      margin-left: unset;
      margin-top: 20px;
    }
  }
}

.goods-flex {
  display: flex;
  flex-wrap: wrap;
  .good-item {
    width: 25%;
    padding: 0 12px;
    text-align: center;
    // margin-bottom: 20px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    // background: #fff;
    .el-button-add-cart {
      position: absolute;
      left: calc(50% - 60px);
    }
    .img {
      position: relative;
      img {
        width: 100%;
        height: 260px;
      }
      .hover-btn {
        position: absolute;
        top: 50%;
        left: calc(50% - 60px);
        display: flex;
        div {
          @include circular($border: none);
          background-color: white;
          @include flexCenter;
        }
        div:first-child {
          margin-right: 20px;
        }
        .good-collect {
          &:hover {
            background: rgb(255, 218, 0);
            color: #000;
          }
           .el-icon-sort {
            transform: rotateZ(90deg);
          }
        }
      }
    }
    .good-content {
      background: #fff;
      &:hover {
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, .1);
        .img-bg {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, .2);
        }
      }
    }
    
    .price {
      color: #F30240;
      font-size: 18px;
      padding-bottom: 20px;
      font-weight: bold;
    }
    .evaluation i {
      color: #D2D2D2;
    }
    .add-cat {
      :deep( .el-button) {
        background-color: rgb(255, 218, 0);
        color: #fff;
        border-color:rgb(255, 218, 0)
      }
    }
  }
}

// .goods-flex {
//   display: grid;
//   // grid-template-columns: repeat(auto-fit, 18%);
//   grid-template-columns: repeat(auto-fit, 16%);
//   grid-gap: 40px;
//   // display: flex;
//   // flex-wrap: wrap;
//   .good-item {
//     // width: 25%;
//     // padding: 0 20px;
//     text-align: center;
//     margin-bottom: 20px;
//     cursor: pointer;
//     box-sizing: border-box;
//     position: relative;
//     background: #fff;
//     .el-button-add-cart {
//       position: absolute;
//       left: calc(50% - 60px);
//     }
//     .img {
//       position: relative;
//       img {
//         width: 100%;
//       }
//       .hover-btn {
//         position: absolute;
//         top: 50%;
//         left: calc(50% - 60px);
//         display: flex;
//         div {
//           @include circular($border: none);
//           background-color: white;
//           @include flexCenter;
//         }
//         div:first-child {
//           margin-right: 20px;
//         }
//       }
//     }
//     &:hover {
//       box-shadow: 0 0 10px 10px rgba(0, 0, 0, .1);
//       .img-bg {
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         background-color: rgba(255, 255, 255, .2);
//       }
//     }
//     .price {
//       color: #F30240;
//       font-size: 18px;
//       padding-bottom: 20px;
//       font-weight: bold;
//     }
//     .evaluation i {
//       color: #D2D2D2;
//     }
//   }
// }
.goods-flex-ml {
  @extend .goods-flex;
  grid-template-columns: repeat(auto-fit, 40%) !important;
}
</style>

